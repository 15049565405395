<template>
	<div class="add-client-form">
		<p v-if="!isEditClient" class="show-form toggle-button dark" v-bind:class="{active: isActive}" data-container="toggle-1" v-on:click="toggleForm()">
			<span class="add"><i class="fas fa-plus-circle"></i></span> {{ isActive ? "Close Form" : "Add a client" }}
		</p>
		<div class="toggle-container" v-bind:class="{active: isActive || isEditClient}" v-if="isActive || isEditClient" id="toggle-1">
			<form @submit.prevent id="add-client-form" class="dark">
				<div class="form-row">
					<div class="upload-photo">
						<div class="photo" :style="logoBgImage()">
							<img v-bind:src="logoBgImage()" v-if="logoBgImage()" />
						</div>
						<label for="upload-photo">Upload profile picture</label>
						<input type="file" id="upload-photo" name="upload-photo" v-on:change="selectFile($event)" />
					</div>
					<!-- end upload-photo -->
				</div>
				<!-- end form-row -->
				<div class="form-row">
					<input type="text" id="company" class="form-control" placeholder="Client Name" required v-model="clientName" />
					<!-- end styled-select -->
				</div>

				<div class="form-row" id="assign-users">
					<label>Assign users</label>
					<div class="column-wrap">
						<div class="styled-select select" id="assign-users-select">
							<!-- This can be reimplemented if necessary, displays full name & email -->
							<!-- <VueNextSelect searchable="" @selected="selectUser($event)" v-bind:options="userSelectOptions" :label-by="(user)=>{return `${user.fullName} - ${user.email}`}" placeholder="Username" close-on-select></VueNextSelect> -->
							<VueNextSelect
								searchable=""
								@selected="selectUser($event)"
								v-bind:options="userSelectOptions"
								:label-by="
									(user) => {
										return `${user.username}`
									}
								"
								placeholder="Username"
								close-on-select
							></VueNextSelect>
						</div>
						<!-- end styled-select -->
						<!-- <span class="add" v-on:click="assignUsersToClient()">Add</span> -->
					</div>
					<div v-if="selectedUsers.length > 0" class="selected-users">
						<span>Users to add:</span>
						<ul class="selected-users__list">
							<li v-for="user in selectedUsers" :key="user.id">
								<div class="profile-image">
									<img :src="user.avatarUrl" :alt="user.fullName" :title="user.fullName" v-if="user.avatarUrl" />
									<div class="user-letter" v-else>{{ user.fullName.charAt(0) }}</div>
								</div>
								<span>{{ user.fullName }}</span>
								<button class="remove" title="Remove user" v-on:click="removeSelectedUser(user.id)">
									<i class="fas fa-minus-circle"></i>
								</button>
							</li>
						</ul>
					</div>
					<!-- end column-wrap -->
				</div>
				<div class="form-row" id="assign-users">
					<label>Assign Main Contact</label>
					<div class="column-wrap">
						<div class="styled-select select" id="assign-users-select">
							<!-- This can be reimplemented if necessary, displays full name & email -->
							<!-- <VueNextSelect :placeholder="getClientMainContact ? getClientMainContact.fullName : 'Assign main contact'" :options="mainContactSelectOptions" :label-by="(user)=>{return `${user.fullName} - ${user.email}`}" close-on-select @selected="selectMainContact($event)"></VueNextSelect> -->
							<VueNextSelect
								:placeholder="getClientMainContact ? getClientMainContact.fullName : 'Assign main contact'"
								:options="mainContactSelectOptions"
								:label-by="
									(user) => {
										return `${user.username}`
									}
								"
								close-on-select
								@selected="selectMainContact($event)"
							></VueNextSelect>
						</div>
						<!-- end styled-select -->
						<!-- <span class="add" v-on:click="assignMainContact()">Assign</span> -->
						<span class="field-message" :class="{error: !assignMainContactResponse.successful}">{{ assignMainContactResponse.message }}</span>
					</div>
					<!-- end column-wrap -->
				</div>
				<!-- end form-row -->
				<button type="submit" @click.prevent="submitForm()">Save</button>
			</form>
		</div>
		<!-- end toggle-container -->
	</div>
	<!-- end add-client -->
</template>
<script>
import VueNextSelect from "vue-next-select"
import "vue-next-select/dist/index.min.css"
import {mapGetters} from "vuex"

export default {
	created() {
		this.$store.dispatch("user/getAllUsers")
	},
	mounted() {
		this.setUserSelectOptions()
		this.selectedMainContact = this.getClientMainContact
		//console.log(this.userSelectOptions);
	},
	data() {
		return {
			isActive: this.$route.name === "Edit Clients" || this.$route.name === "Edit Client" ? true : false,
			mainContactFieldUpdated: false,
			company: "",
			selectedFile: null,
			previewUrl: "",
			uploadLogo: "",
			files: [],
			selectedUser: {},
			selectedUsers: [],
			selectedMainContact: {},
			assignMainContactResponse: "",
			userSelectOptions: [{username: "Jack Loader"}, {username: "Crispy Bacon"}, {username: "Tom Phippen"}, {username: "Jon M"}, {username: "Luke Block Delete"}],
		}
	},
	methods: {
		removeSelectedUser(userId) {
			const indexOfUser = this.selectedUsers.findIndex((user) => {
				return user.id === userId
			})

			this.selectedUsers.splice(indexOfUser, 1)
		},
		logoBgImage() {
			// let bgImage = this.previewUrl ? this.previewUrl : this.getClientAvatarUrl
			// let style = {backgroundImage: `url("${bgImage}")`}
			// console.log(style)
			// return style

			let bgImage = this.previewUrl ? this.previewUrl : this.getClientAvatarUrl
			return bgImage
		},
		selectFile($event) {
			let file = $event.target.files[0]
			let allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"]

			// Check if file is of allowed types
			if (!allowedFileTypes.includes(file.type)) {
				alert("File must be a jpg, jpeg or png")
			} else if (file.size > 1048576) {
				// Check if file is below 1mb
				alert("File is larger than 1mb")
			} else {
				this.selectedFile = file
				this.previewUrl = URL.createObjectURL(this.selectedFile)
			}
		},
		selectMainContact($event) {
			this.selectedMainContact = $event
			this.mainContactFieldUpdated = true
		},
		selectUser($event) {
			// If the selected user isn't already in the array of selected users
			console.log(this.selectedUsers.find((user) => user.id === $event.id))
			if (!this.selectedUsers.find((user) => user.id === $event.id)) {
				// Add the user to the list
				this.selectedUsers.push($event)
			}
			console.log(this.selectedUsers)
			this.selectedUser = $event
		},
		async setUserSelectOptions() {
			let allUsers = await this.$store.dispatch("user/getAllUsers")
			let clientUsers = this.getClientUsers
			// Filter out users that are already assigned to the client
			this.userSelectOptions = allUsers.filter((user) => !clientUsers.find((clientUser) => user.id == clientUser.id))
		},
		async assignUsersToClient() {
			const userIds = []
			const users = this.selectedUsers

			for (let user of users) {
				userIds.push(user.id)
			}

			const client = this.$store.getters["client/getClientData"]
			const response = await this.$store.dispatch("createClientUserRelations", {client, userIds})
			await this.$store.dispatch("client/fetchClientData", client.slug)

			// Clear the selected users
			this.selectedUsers = []

			this.$emit("newUserAssignedToClient")
			return response
		},
		async assignMainContact() {
			// set the user & client to be passed
			const user = this.selectedMainContact
			const client = this.$store.getters["client/getClientData"]
			// Fire the API request to assign the main contact
			let assignMCResponse = await this.$store.dispatch("assignUserAsMainContact", {client, user})
			// When main contact has been assigned, refetch client data
			await this.$store.dispatch("client/fetchClientData", )
			return assignMCResponse
		},
		toggleForm() {
			this.isActive = !this.isActive
			this.$emit("formOpened")
		},
		handleFileUpload(event) {
			this.file = event.target.files[0]
		},
		async submitForm() {
			let messages = []

			// Carry out user functions, assign main contact & users to client
			const userFunctions = async () => {
				if (this.selectedUsers.length > 0) {
					let assignedUsersResponse = await this.assignUsersToClient()
					if (assignedUsersResponse?.message) messages.push(assignedUsersResponse?.message)
				}
				if (this.mainContactFieldUpdated) {
					let assignMainContactResponse = await this.assignMainContact()
					if (assignMainContactResponse?.message) messages.push(assignMainContactResponse?.message)
					this.mainContactFieldUpdated = false
				}
                return;
			}

			if (this.$route.name === "Edit Client" || this.$route.name === "Edit Clients") {
				let updatedClient = await this.$store.dispatch("client/updateClient", this.selectedFile)
				if (updatedClient?.message) messages.push(updatedClient?.message)
                await this.$store.dispatch("client/fetchClientData", this.getClientSlug)
				await userFunctions()
				this.$emit("clientUpdated", messages)
			} else {
				let createClientResponse = await this.$store.dispatch("client/createClient", this.selectedFile)
				if (createClientResponse?.message) messages.push(createClientResponse?.message)
				await userFunctions()
				this.$store.dispatch("client/clearClientData")
				this.$emit("newClientAdded", messages)
			}
		},
	},
	computed: {
		mainContactSelectOptions() {
			return [...this.getClientUsers, ...this.selectedUsers]
		},
		isEditClient() {
			return this.$route.name === "Edit Client" || this.$route.name === "Edit Clients"
		},
		clientName: {
			get() {
				return this.$store.getters["client/getClientName"] || ""
			},
			set(value) {
				this.$store.commit("client/setClientName", value)
			},
		},
		...mapGetters("client", ["getClientUsers", "getClientMainContact", "getClientAvatarUrl", "getClientSlug"]),
	},
	components: {
		VueNextSelect,
	},
}
</script>
<style scoped>
@import "../../../node_modules/vue-next-select/dist/index.min.css";
</style>
