<template>
	<div class="clients">
		<h1>Edit client</h1>
		<!-- GET CLIENT FORM -->
		<AddClientForm @clientUpdated="clientUpdated"></AddClientForm>
        <p v-for="message in formMessages" :key="message">
            {{message}}
        </p>
	<!-- GET USERS ASSIGNED LIST --> 
		<UsersList :key="usersListKey" @userRemoved="refreshUsersList"></UsersList>
	</div>
</template>
<script>
import AddClientForm from "../../components/forms/AddClientForm.vue"
import UsersList from "../../components/lists/UsersList.vue"

export default {
	data() {
		return {
			usersListKey: 1,
      formMessages: [],
		}
	},
	components: {
		AddClientForm,
		UsersList,
	},
	methods: {
        clientUpdated(messages){
            this.formMessages = messages;
            this.refreshUsersList();
        },
		refreshUsersList() {
			this.usersListKey++
		},
	},
}
</script>
